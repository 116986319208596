<template>
  <validation-observer
    ref="form"
    class="page page__login login"
    tag="div"
  >
    <form
      class="login__form"
      @submit.prevent="resetPassword"
    >
      <esmp-loader v-if="isLoading" fix />

      <h1 class="login__title">
        Восстановление пароля
      </h1>

      <div class="login__caption">
        Укажите логин или email, используемые вами на сайте, и вам на почту
        будет отправлена информация по восстановлению пароля.
      </div>

      <validation-provider
        rules="required"
        v-slot="v"
        name="Логин или email"
        tag="div"
        class="login__input"
      >
        <esmp-input
          v-model="resetPasswordLogin"
          label="Введите логин или email"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>

      <div class="login__input">
        <esmp-button full-width type="submit">
          Отправить
        </esmp-button>
      </div>

      <esmp-button view="function" @click="goToLogin">
        Вернуться к авторизации
      </esmp-button>
    </form>
  </validation-observer>
</template>

<script>
import pluralization from '@/utils/pluralization';
import isAvailableForResetPassword from '@/mixins/isAvailableForResetPassword';

export default {
  name: 'ForgetPassword',

  mixins: [isAvailableForResetPassword],

  data() {
    return {
      resetPasswordLogin: '',
      isLoading: false,
    };
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },

    async resetPassword() {
      const isFormValid = await this.$refs.form.validate();

      if (!isFormValid) return;

      try {
        this.isLoading = true;

        const {
          data: { cntRemainderRequests: attemptsCount },
        } = await this.$API.resetPassword.sendEmail(this.resetPasswordLogin);

        const attemptsCountString = `${pluralization(
          attemptsCount,
          ['Осталась', 'Осталось', 'Осталось'],
        )} ${attemptsCount} ${pluralization(
          attemptsCount,
          ['попытка', 'попытки', 'попыток'],
        )}`;

        this.$EsmpNotify.$show('Инструкция по восстановлению пароля отправлена вам на почту!', 'success');
        this.$EsmpNotify.$show(`${attemptsCountString} восстановления пароля на сессию`, 'info');

        await this.$router.push({ name: 'ResetPassword' });
      } catch (e) {
        this.$EsmpNotify.$show('Введенные данные некорректны. Проверьте правильность введенных данных.', 'error');
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    // fix for IE
    this.$EsmpLoader.hide();
  },
};
</script>
