<template>
  <validation-observer
    ref="form"
    class="page page__login login"
    tag="div"
  >
    <form
      class="login__form"
      @submit.prevent="changePassword"
    >
      <esmp-loader v-if="isLoading" fix />

      <h1 class="login__title">
        Создание нового пароля
      </h1>

      <div class="login__caption">
        Введите токен, присланный вам на почту, или перейдите по ссылке из письма, а также введите новый пароль.
      </div>

      <validation-provider
        rules="required"
        v-slot="v"
        name="Токен"
        tag="div"
        class="login__input"
      >
        <esmp-input
          v-model="token"
          label="Введите токен из письма"
          :options="{autocomplete: 'off'}"
          :disabled="isTokenFromRouteQuery"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>

      <validation-provider
        rules="required|passwordComplexity"
        v-slot="v"
        name="Пароль"
        tag="div"
        class="login__input"
      >
        <esmp-input
          v-model="password"
          :options="{type: 'password', autocomplete: 'off'}"
          label="Введите новый пароль"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>

      <validation-provider
        rules="required|passwordComplexity|confirmed:Пароль"
        v-slot="v"
        name="Подтверждение пароля"
        tag="div"
        class="login__input"
      >
        <esmp-input
          v-model="passwordConfirm"
          :options="{type: 'password', autocomplete: 'off'}"
          label="Подтвердите пароль"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>

      <div class="login__input">
        <esmp-button full-width type="submit">
          Сохранить
        </esmp-button>
      </div>

      <esmp-button view="function" @click="goToLogin">
        Вернуться к авторизации
      </esmp-button>
    </form>
  </validation-observer>
</template>

<script>
import isAvailableForResetPassword from '@/mixins/isAvailableForResetPassword';

export default {
  name: 'ResetPassword',

  mixins: [isAvailableForResetPassword],

  data() {
    return {
      password: '',
      passwordConfirm: '',
      token: this.$route.query.token,
      isLoading: false,
    };
  },

  computed: {
    isTokenFromRouteQuery() {
      return !!this.$route.query.token;
    },
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },

    async changePassword() {
      const isFormValid = await this.$refs.form.validate();

      if (!isFormValid) return;

      try {
        this.isLoading = true;

        await this.$API.resetPassword.changePassword(this.password, this.token);

        this.$EsmpNotify.$show('Пароль успешно изменен!', 'success');

        await this.$router.push({ name: 'Login' });
      } catch (e) {
        this.$EsmpNotify.$show('Введенные данные некорректны. Проверьте правильность введенных данных.', 'error');
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    // fix for IE
    this.$EsmpLoader.hide();
  },
};
</script>
