import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('system', ['config']),
  },

  watch: {
    config: {
      immediate: true,
      handler(config) {
        if (!config.isResetPasswordAvailable) {
          this.$router.push({ name: 'Error404' });
        }
      },
    },
  },
};
